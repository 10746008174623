<template>
    <div v-if="action || properties && properties.get(propertyKey)">
        <div class="flex flex-col questions-input">
            <h3 v-if="title" class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">{{title}}</h3>
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Question
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Required
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="action" class="bg-white" v-for="(question,key) in questions"><!-- bg-gray-50 -->
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    <input type="text" v-model="question.question" class="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" @input="change">
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <input type="checkbox" :value="true" v-model="question.is_required" @change="change" class="form-checkbox">
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top">
                                    <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem(key);">remove</span>
                                </td>
                            </tr>

                            <tr v-if="!action" class="bg-white" v-for="question in questions"><!-- bg-gray-50 -->
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{question.question}}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <svg v-if="question.is_required" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top">

                                </td>
                            </tr>
                            <tr v-if="action" class="bg-white text-center">
                                <td colspan="100">
                                    <div class="grid grid-cols-3 p-3">
                                        <a-button @click.native="addNewItem()" data-cy="add-item"
                                        class="col-start-2 inline-flex justify-center">
                                        + New Question</a-button>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PropertyOrField from "@/components/PropertyOrField";
import Button from "@/v2/buttons/Button";
import GbpInput from "@/components/Action/Fields/GbpInput";

export default {
    components: {
        PropertyOrField,
        'a-button': Button
    },
    data() {
        return {
            questions: []
        }
    },
    props: {
        action: {
            type: Object,
        },
        propertyKey: {
            type: String,
            default: 'questions'
        },
        properties: {
            type: Object
        },
        title:{
            type: String,
            default: null
        }
    },
    methods: {
        addNewItem() {
            this.questions.push({question:'', is_required: false});
            this.$emit('change');
        },
        removeItem(key) {
            this.$set(this.questions, this.questions.splice(key, 1));
            this.$emit('change');
        },
        change() {
            this.$emit("change");
        },
    },
    created() {
        if(this.properties) {
            this.questions = this.properties.get(this.propertyKey);
        }
    },
    computed:{
        valueField() {
            return {'title':'value', 'validation':null}
        },
        multipleField() {
            return {'title':'Multiple', 'validation':null, 'options': [{'title':'Yes','value':1},{'title':'No','value':0, 'selected':1}], 'value': 0}
        },
        defaultQuantityField() {
            return {'title':'Default Quantity', 'validation':null}
        },
    },
    watch: {
        questions: {
            handler(newValue, oldValue) {
                this.$emit("input", JSON.stringify(newValue));
            },
            deep: true
        }
    },
}
</script>

<style>
.questions-input label{
    display:none
}
</style>
