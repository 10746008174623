<template>
    <!-- Comments-->
    <section aria-labelledby="notes-title">

        <div class="text-left">
            <div class="divide-y divide-gray-200">
                <div class="px-4 py-6 sm:px-6">
                    <div class="flow-root">
                        <div class="mb-5">Activity</div>
                        <ul role="list" class="-mb-8">
                            <div v-for="(activity, index) in activities">
                                <li>
                                    <div class="relative pb-8">
                                        <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" v-if="index + 1 !== activities.length"></span>
                                        <div class="relative flex items-start space-x-3">
                                            <div>
                                                <div class="relative" :class="{'px-0': activity['description'].startsWith('Comment'), 'px-1': !activity['description'].startsWith('Comment')}">
                                                    <template v-if="activity['description'].startsWith('Comment')">
                                                        <div class="h-10 w-10 rounded-full bg-secondary-lightpeach flex items-center justify-center ring-8 ring-secondary-lightbacka flex items-center justify-center">
                                                            {{ initials(activity['actioned_by']) }}
                                                        </div>
                                                        <span class="absolute -bottom-0.5 -right-1 rounded-tl px-0.5 py-px">
                                                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                              <path stroke-linecap="round" stroke-linejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                                          </svg>
                                                        </span>
                                                    </template>
                                                    <div v-else class="h-8 w-8 bg-secondary-lightpeach rounded-full ring-8 ring-secondary-lightbacka flex items-center justify-center">
                                                        <div class="h-8 w-8 rounded-full bg-secondary-lightpeach flex items-center justify-center ring-8 ring-secondary-lightbacka flex items-center justify-center">
                                                            {{ initials(activity['actioned_by']) }}
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="min-w-0 flex-1 py-1.5">
                                                <div v-if="!activity['description'].startsWith('Comment')" class="text-sm text-gray-500">
                                                    <a href="#" class="font-medium text-gray-900 dark:text-v3-gray-200">{{ activity['actioned_by'] }}</a>
                                                    {{activity['description']}}
                                                    <span class="whitespace-nowrap">{{activity['date']}}</span>
                                                </div>
                                                <template v-else>
                                                    <div>
                                                        <div class="text-sm">
                                                            <a href="#" class="font-medium text-gray-900">{{ activity['added_by'] }}</a>
                                                        </div>
                                                        <p class="mt-0.5 text-sm text-gray-500">Commented on
                                                            {{ activity['date'] }}</p>
                                                    </div>
                                                    <div class="mt-2 text-sm text-gray-700">
                                                        <p>{{activity['description'].replace('Comment - ', '')}}</p>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import Siren from "super-siren";
import {mapGetters} from "vuex";
export default {
    name: "VoucherActivityFeed",
    data(){
        return {
            comment: null,
            submitting: false,
            currentUserName: null,
            currentUserProfile: null
        }
    },
    components: {
        'a-button': Button
    },
    props:{
        entity: {
            type: Object
        },
    },
    computed: {
        activities(){
            return  this.entity.properties.get('activity_stream');
        },
        addCommentAction(){
            return this.entity.actions.filter(action => action.name === 'add-comment').first();
        },
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
            message: "getMessage",
            messageWithoutTimeout: "getMessageWithoutTimeout"
        }),
    },
    methods:{
        getNumberOfDaysElapsed(fromDate){
            let splitFromDate1 = fromDate.split(' ');
            let splitFromDate2 = splitFromDate1[1].split('/')
            let rejoinedDate = splitFromDate2[1] + '/' + splitFromDate2[0] + '/' + splitFromDate2[2] + ' ' + splitFromDate1[0];
            let date_1 = new Date(rejoinedDate);
            let date_2 = new Date();

            let difference = date_1.getTime() - date_2.getTime();
            let TotalDays = Math.ceil(difference / (1000 * 3600 * 24)) * -1;
            return TotalDays;
        },
        performAddComment() {
            this.submitting = true;
            this.addCommentAction.perform({'comment': this.comment}).then(res => {
                this.submitting = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                this.$router.go(0);
                this.$emit('success', res);
            }).catch(error => {
                this.submitting = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        initials(userName) {
            if (userName) {
                return userName
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        }
    },
    mounted(){

        this.currentUserProfile = this.user['profile_image'];
        this.currentUserName = this.user['name'];

        this.$forceUpdate();
    }
}
</script>

<style scoped>

</style>
