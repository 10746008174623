<template>
    <pagination title="Details" :entities="entity" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false" :withEditIcon="action" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')" header-colour-class="bg-transparent">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Work By
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="subcontract_name"
                        :action="editing ? action: null"
                        fieldId="operative_id"
                        :showLabel="false"
                        v-model="updateActionData['operative_id']">
                    </property-or-field>
                    <div v-show="errors.has('operative_id')" class="text-red-600">{{ errors.first('operative_id') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Project & location
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="location">
                    </property-or-field>
                    <div v-show="errors.has('location')" class="text-red-600">{{ errors.first('location') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Works Done According To Drawing
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <span v-if="!editing">{{entity.properties ? 'yes' : 'No'}}</span>
                    <property-or-field v-else
                        :properties="entity.properties"
                        property-key="work_done"
                        :action="editing ? action: null"
                        fieldId="work_done"
                        :showLabel="false"
                        v-model="updateActionData['work_done']">
                    </property-or-field>
                    <div v-show="errors.has('work_done')" class="text-red-600">{{ errors.first('work_done') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr v-if="entity.properties.get('work_done_comment') || editing">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Works Done Comment
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                                       :properties="entity.properties"
                                       property-key="work_done_comment"
                                       :action="editing ? action: null"
                                       fieldId="work_done_comment"
                                       :showLabel="false"
                                       v-model="updateActionData['work_done_comment']">
                    </property-or-field>
                    <div v-show="errors.has('work_done_comment')" class="text-red-600">{{ errors.first('work_done_comment') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Date Of Works
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="date_of_works">
                    </property-or-field>
                    <div v-show="errors.has('date_of_works')" class="text-red-600">{{ errors.first('date_of_works') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Notes
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="additional_notes"
                        :action="editing ? action: null"
                        fieldId="additional_notes"
                        :showLabel="false"
                        v-model="updateActionData['additional_notes']">
                    </property-or-field>
                    <div v-show="errors.has('additional_notes')" class="text-red-600">{{ errors.first('additional_notes') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>

            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Voucher
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <svg v-if="entity.properties.get('voucher') === 'Yes'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                    <svg v-else-if="entity.properties.get('voucher') === 'No'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <template v-else>
                        {{ entity.properties.get('voucher') }}
                    </template>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr v-if="editing">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    On Site Confirmation Image
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="on_site_confirmation_image"
                        :action="editing ? action: null"
                        fieldId="on_site_confirmation_image"
                        :showLabel="false"
                        v-model="updateActionData['on_site_confirmation_image']">
                    </property-or-field>
                    <div v-show="errors.has('on_site_confirmation_image')" class="text-red-600">{{ errors.first('on_site_confirmation_image') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr v-if="editing">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Construction Drawing
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="construction_drawing"
                        :action="editing ? action: null"
                        fieldId="construction_drawing"
                        :showLabel="false"
                        v-model="updateActionData['construction_drawing']">
                    </property-or-field>
                    <div v-show="errors.has('construction_drawing')" class="text-red-600">{{ errors.first('construction_drawing') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
    inject: ["$validator"],
  name: 'QaDetailsPagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    entity: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: true
      },
      action:{
        type: Object,
          default: null
      },
    editing:{
        type: Boolean,
        default: false
    },
      updateActionData:{
        type: Object
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
