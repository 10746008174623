<template>
    <pagination title="Payment Types" :response="response" :entities="paymentTypes" :checkSize="false" :has-entities="true" header-colour-class="bg-transparent" :withEditIcon="true" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Payment Type
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Value</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Multiple</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Default Value</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold"></th>

        </template>
        <template v-slot:items>
            <tr v-if="updateAction && editing" v-for="(paymentType,key) in paymentTypes"><!-- bg-gray-50 -->
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <input type="text" v-model="paymentType.payment_type" class="p-1 bg-v3-gray-900 bg-opacity-5 text-v3-gray-900 text-opacity-70 dark:bg-white dark:text-white dark:text-opacity-70 dark:bg-opacity-5 placeholder-v3-gray-900 placeholder-opacity-70 dark:placeholder-white dark:placeholder-opacity-70 dark:placeholder-op dark: form-input focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md border-0" @input="change">
                </td>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                    <gbp-input :value="paymentType.value" :field="valueField" v-model="paymentType.value" @input="change" :showLabel="false"></gbp-input>
                </td>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                    <radio-input :value="paymentType.multiple" :field="multipleField" v-model="paymentType.multiple" @input="change" :showLabel="false"></radio-input>
                </td>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                    <number-input v-if="paymentType.multiple" :value="paymentType.default_quantity" :field="defaultQuantityField" v-model="paymentType.default_quantity" @input="change" :showLabel="false"></number-input>
                    <template v-else>{{paymentType.default_quantity}}</template>
                </td>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                    <span class="cursor-pointer font-normal underline" @click="removeItem(key);">remove</span>
                </td>
            </tr>
            <template v-if="!updateAction || !editing">
                <tr v-for="paymentType in paymentTypes"><!-- bg-gray-50 -->
                    <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                        {{paymentType.payment_type}}
                    </td>
                    <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                        {{paymentType.value_as_decimal}}
                    </td>
                    <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                        {{paymentType.multiple}}
                    </td>
                    <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                        {{paymentType.default_quantity}}
                    </td>
                    <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">

                    </td>
                </tr>
            </template>
            <tr v-if="updateAction && editing" class="text-center">
                <td colspan="100">
                    <div class="grid grid-cols-3 p-3">
                        <custom-button @click.native="addNewItem()" data-cy="add-item"
                                  class="col-start-2 inline-flex justify-center">
                            + New Question</custom-button>

                    </div>
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import NewPaymentVoucherQas from "@/components/NewPaymentVoucherQas.vue";
import PropertyOrAutocompleteField from "@/components/PropertyOrAutocompleteField.vue";
export default {
  name: 'QaCategoriesPaymentTypesPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false,
          items: []
      }
    },
    components: {
        PropertyOrAutocompleteField,
        NewPaymentVoucherQas,
        CustomButton,
        DownloadPdfIconWithLink, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        },
        paymentTypes() {
            if (!this.category) {
                return null;
            }
            return this.category.properties.get('payment_types');
        },
        updateAction(){
            return this.category.actions.filter(action => action.name === 'update-template').first();
        },
        formData() {
            if (!this.updateAction.fields) {
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        valueField() {
            return {'title':'value', 'validation':null}
        },
        multipleField() {
            return {'title':'Multiple', 'validation':null, 'options': [{'title':'Yes','value':1},{'title':'No','value':0, 'selected':1}], 'value': 0}
        },
        defaultQuantityField() {
            return {'title':'Default Quantity', 'validation':null}
        },
    },
  props: {
      category: {},
      response: {
        type: Object,
          default: null
      },
      editing: {
          type: Boolean,
          default: null
      }
  },
    methods:{
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        addNewItem() {
            this.paymentTypes.push({payment_type:null, value:null, multiple:0, default_quantity:1});
            this.$emit('change');
        },
        removeItem(key) {
            this.$set(this.paymentTypes, this.paymentTypes.splice(key, 1));
            this.$emit('change');
        },
        change() {
            this.$emit("change");
        },
        saveItems(){
          this.formData['payment_types'] = JSON.stringify(this.paymentTypes);
            return this.updateAction.perform(this.formData);
        }
    }
}
</script>
