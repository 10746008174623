<template>
    <pagination title="Pre-check Questions" :response="response" :entities="questions" :checkSize="false" :has-entities="true" header-colour-class="bg-transparent" :withEditIcon="true" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Question
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Required</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold"></th>

        </template>
        <template v-slot:items>
            <tr v-if="updateAction && editing" v-for="(question,key) in questions"><!-- bg-gray-50 -->
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <input type="text" v-model="question.question" class="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" @input="change">
                </td>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                    <input type="checkbox" :value="true" v-model="question.is_required" @change="change" class="form-checkbox">
                </td>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                    <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem(key);">remove</span>
                </td>
            </tr>
            <template v-if="!updateAction || !editing">
                <tr v-for="question in questions"><!-- bg-gray-50 -->
                    <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                        {{question.question}}
                    </td>
                    <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                        <svg v-if="question.is_required" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                    </td>
                    <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">

                    </td>
                </tr>
            </template>
            <tr v-if="updateAction && editing" class="text-center">
                <td colspan="100">
                    <div class="grid grid-cols-3 p-3">
                        <custom-button @click.native="addNewItem()" data-cy="add-item"
                                  class="col-start-2 inline-flex justify-center">
                            + New Question</custom-button>

                    </div>
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import NewPaymentVoucherQas from "@/components/NewPaymentVoucherQas.vue";
import PropertyOrAutocompleteField from "@/components/PropertyOrAutocompleteField.vue";
export default {
  name: 'QaCategoriesPreCheckQuestionsPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false,
          items: []
      }
    },
    components: {
        PropertyOrAutocompleteField,
        NewPaymentVoucherQas,
        CustomButton,
        DownloadPdfIconWithLink, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        },
        questions() {
            if (!this.category) {
                return null;
            }
            return this.category.properties.get('pre_check_questions');
        },
        updateAction(){
            return this.category.actions.filter(action => action.name === 'update-template').first();
        },
        formData() {
            if (!this.updateAction.fields) {
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
  props: {
      category: {},
      response: {
        type: Object,
          default: null
      },
      editing: {
          type: Boolean,
          default: null
      }
  },
    methods:{
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        addNewItem() {
            this.questions.push({question:'', is_required: false});
            this.$emit('change');
        },
        removeItem(key) {
            this.$set(this.questions, this.questions.splice(key, 1));
            this.$emit('change');
        },
        change() {
            this.$emit("change");
        },
        saveItems(){
          this.formData['pre_check_questions'] = JSON.stringify(this.questions);
            return this.updateAction.perform(this.formData);
        },
        valueField() {
            return {'title':'value', 'validation':null}
        },
        multipleField() {
            return {'title':'Multiple', 'validation':null, 'options': [{'title':'Yes','value':1},{'title':'No','value':0, 'selected':1}], 'value': 0}
        },
        defaultQuantityField() {
            return {'title':'Default Quantity', 'validation':null}
        },
    }
}
</script>
