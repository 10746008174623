<template>
    <div class="flex flex-col items-start">
        <span class="relative inline-flex items-center px-2.5 py-0.5 rounded-lg text-sm font-medium leading-5" :class="colourClasses">
          {{status}}
            <span v-if="hasExpectedByDatePassed && status === 'awaiting-delivery'" class="absolute top-0 right-2 block h-3.5 w-3.5 rounded-full text-red-500 -mt-2 -mr-3" :title="expectedByDateTitle">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
            </span>
        </span>
        <span class="scheduled-delivery-date px-2.5" v-if="deliveryDate">
            ETA: {{deliveryDate}}
        </span>
    </div>
</template>

<script>
    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat'
    export default {
        props: {
            status: {
                type: String,
            },
            expectedByDate:{
                type:String
            },
            deliveryDate: {
                type: String,
            },
            additionalClasses: {
                type: String,
            },
        },
        computed: {
            hasExpectedByDatePassed(){
                dayjs.extend(customParseFormat);
                return dayjs(this.expectedByDate, 'DD/MM/YYYY').isBefore(dayjs());
            },
            expectedByDateTitle(){
                return `The expected by date "${this.expectedByDate}" has passed.`;
            },
            colourClasses() {
                if(this.status === 'pending' || this.status === 'pending-approval'  || this.status === 'draft' || this.status === 'supervisor'){
                    return 'bg-yellow-300 text-yellow-800';
                }
                if(this.status === 'out-for-quote'){
                    return 'bg-purple-100 text-purple-800';
                }
                if(this.status === 'quotes-received'){
                    return 'bg-yellow-100 text-yellow-800';
                }
                if(this.status === 'in-progress'){
                    return 'bg-red-300 text-red-800';
                }
                if(this.status === 'ready-for-quote'|| this.status === 'new'){
                    return 'bg-green-100 text-green-800';
                }
                if(this.status === 'awaiting-cms-quote'){
                    return 'bg-purple-500 text-purple-200';
                }
                if(this.status === 'awaiting-cms-quote-approval'){
                    return 'bg-blue-500 text-blue-200';
                }
                if(this.status === 'awaiting-sm-approval'){
                    return 'bg-blue-100 text-blue-800';
                }
                if(this.status === 'awaiting-approval'){
                    return 'bg-indigo-100 text-indigo-800';
                }
                if(this.status === 'awaiting-senior-approval'){
                    return 'bg-indigo-600 text-indigo-100';
                }
                if(this.status === 'awaiting-delivery'){
                    return 'bg-pink-100 text-pink-800';
                }
                if(this.status === 'completed'){
                    return 'bg-green-300 text-green-800';
                }
                if(this.status === 'ready-for-cms-quote'){
                    return 'bg-green-500 text-green-900';
                }
                if(this.status === 'partial-delivery'){
                    return 'bg-blue-300 text-blue-800';
                }
                if(this.status === 'awaiting-invoice-upload'){
                    return 'bg-indigo-300 text-indigo-800';
                }
                if(this.status === 'awaiting-client-approval'){
                    return 'bg-yellow-500 text-yellow-100';
                }
                if(this.status === 'scheduled' || this.status === 'archived-for-cms'){
                    return 'bg-red-100 text-red-800';
                }

                if(this.status === 'awaiting-proforma-payment'){
                    return 'bg-purple-300 text-purple-800';
                }
                if(this.status === 'ready-to-order'){
                    return 'bg-green-200 text-green-800'
                }
                if(this.status === 'awaiting-credit'){
                    return 'bg-pink-300 text-pink-800';
                }
                if(this.status === 'payment-check'){
                    return 'bg-purple-300 text-purple-800';
                }
                if(this.status === 'Pre-Check Required'){
                    return 'bg-v3-green bg-opacity-20 text-v3-green';
                }

                return 'bg-gray-100 text-gray-800';
            }
        },
    }
</script>
