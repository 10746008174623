<template>
    <pagination title="Day Pay Requests" :response="response" :entities="dayPays" :checkSize="false" :has-entities="true" header-colour-class="bg-transparent" :withEditIcon="true" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Day Pay Request
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold"></th>

        </template>
        <template v-slot:items>
            <tr v-for="dayPay in dayPays">
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <div>
                        {{dayPay['name']}} - hours: {{dayPay['hours']}}
                    </div>
                </td>
                <th class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                </th>
            </tr>
            <tr v-for="(item, index) in items">
                <td colspan="1">
                    <div>
                        <property-or-autocomplete-field ref="day_pay_id"
                            :action="addDayPayAction" fieldId="day_pay_id"
                            propertyKey="day_pay_id"
                            v-model="items[index]['day_pay_id']"
                            :showLabel="false"></property-or-autocomplete-field>
                    </div>
                </td>
                <th class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                    <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem();">remove</span>
                </th>
            </tr>
            <tr v-if="addDayPayAction && editing">
                <td colspan="2" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium" >
                    <div class="flex justify-center">
                        <custom-button @click.native="addNewItem">
                            + Add Day Pay Request
                        </custom-button>
                    </div>
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import dayjs from "dayjs";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import NewPaymentVoucherQas from "@/components/NewPaymentVoucherQas.vue";
import PropertyOrAutocompleteField from "@/components/PropertyOrAutocompleteField.vue";
export default {
  name: 'VoucherDayPayPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false,
          items: []
      }
    },
    components: {
        PropertyOrAutocompleteField,
        NewPaymentVoucherQas,
        CustomButton,
        DownloadPdfIconWithLink, FlagOrderAction, StatusBadge, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.voucher.actions.filter(action => action.name === 'filter').first();
        },
        dayPays() {
            if (!this.voucher) {
                return null;
            }
            return this.voucher.properties.get('day_pay_requests');
        },
        addDayPayAction(){
            return this.voucher.actions.filter(action => action.name === 'add-day-pay').first();
        }
    },
  props: {
    voucher: {},
      response: {
        type: Object,
          default: null
      },
      editing: {
          type: Boolean,
          default: null
      }
  },
    methods:{
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        addNewItem() {
            this.items.push({'day_pay_id': null });
            this.$emit('change');
        },
        newItemActionData(index) {
            if (!this.addDayPayAction.fields) {
                return {};
            }
            var payload = {};
            this.addDayPayAction.fields.forEach(function (field) {
                if (field.value !== undefined) {
                    payload[field.name] = field.value;
                }
            }, this);
            return payload;
        },
        removeItem(index) {
          this.items.splice(index, 1);
        },
        saveDayPays(){
            let updateItemPromises = [];
            let that = this;
            this.items.forEach(function (promise) {
                updateItemPromises.push(that.addDayPayAction.perform(promise));
            })

            return Promise.all(updateItemPromises);
        }
    }
}
</script>
